import React from "react";
import { Link as ReachLink } from "@reach/router";
import { Theme, makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid, { GridProps } from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";

import { PrimaryButton as ButtonComponent } from "~/components/Button";

import type { CreateCSSProperties } from "@material-ui/core/styles/withStyles";

const buttonStyles = makeStyles(({ palette }) => ({
	button: {
		position: "relative",
		marginTop: 15,
		padding: "10px 12px",
		width: "100%",
	},
	spinnerContainer: {
		position: "absolute",
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		display: "flex",
		flexFlow: "row nowrap",
		justifyContent: "center",
		alignItems: "center",
	},
	spinner: {
		color: palette.text.contrast,
	},
}));

interface IBaseActionProps {
	label?: string;
	loading?: boolean;
	disabled?: boolean;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	submit?: boolean;
	grid?: GridProps;
	className?: string;
}

export const Button: React.FC<IBaseActionProps> = ({
	label = "",
	loading,
	disabled,
	submit,
	onClick,
	grid = {},
	className,
}) => {
	const styles = buttonStyles();
	const gridProps: GridProps = {
		xs: 12,
		...grid,
	};

	return (
		<Grid item {...gridProps}>
			<ButtonComponent
				className={clsx(styles.button, className)}
				type={submit ? "submit" : "button"}
				onClick={onClick}
				disabled={disabled}
			>
				<span
					style={
						loading
							? {
									visibility: "hidden",
							  }
							: {}
					}
				>
					{label}
				</span>
				{loading && (
					<Box className={styles.spinnerContainer}>
						<CircularProgress
							size="1.5em"
							className={styles.spinner}
						/>
					</Box>
				)}
			</ButtonComponent>
		</Grid>
	);
};

const linkStyles = makeStyles<Theme, ILinkProps>((theme) => ({
	link: ({ compact = true }) => {
		const css: CreateCSSProperties<ILinkProps> = {
			color: theme.palette.primary.contrastText,
			textAlign: "center",
			margin: "auto",
		};
		if (compact) {
			const adjustedMargin = `${-(
				theme.shape.authFormGutter / 2
			)}px !important`;
			css.marginTop = adjustedMargin;
			css.marginBottom = adjustedMargin;
		}
		return css;
	},
}));

interface ILinkProps extends React.ComponentProps<typeof ReachLink> {
	compact?: boolean;
	grid?: GridProps;
}

export const Link: React.FC<ILinkProps> = (props) => {
	const {
		className,
		grid = {},
		compact = true,
		children,
		...linkProps
	} = props;
	const styles = linkStyles(props);
	const gridProps: GridProps = {
		xs: 12,
		...grid,
	};
	return (
		<Grid item {...gridProps}>
			<ReachLink {...linkProps} className={clsx(styles.link, className)}>
				{children}
			</ReachLink>
		</Grid>
	);
};
