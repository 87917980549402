import React, { useState, forwardRef } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { Input, IInputProps } from "~/components/Input";

interface IPasswordProps extends IInputProps {
	label?: string;
	hideLabel?: boolean;
	hideIcon?: boolean;
	type?: "current-password" | "new-password";
	update?: (newVal: string) => void;
}

export const Password = forwardRef<HTMLInputElement, IPasswordProps>(
	function Password(
		{
			hideIcon,
			update = () => null,
			hideLabel,
			label,
			id,
			type,
			InputProps = {},
			...props
		},
		ref,
	) {
		const [showPassword, setShowPassword] = useState(false);

		return (
			<Input
				label={!hideLabel ? label || "password" : ""}
				update={update}
				autoComplete={type ? type : "current-password"}
				ref={ref}
				{...props}
				type={showPassword ? "text" : "password"}
				InputProps={{
					...InputProps,
					endAdornment: !hideIcon && (
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={() => setShowPassword(!showPassword)}
								onMouseDown={(e) => e.preventDefault()}
							>
								{showPassword ? (
									<Visibility />
								) : (
									<VisibilityOff />
								)}
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
		);
	},
);
