import React, { forwardRef } from "react";
import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import { Typography } from "~/components/Typography";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { brandingColor } from "~/styles/colors";

export interface IInputProps
	extends Omit<TextFieldProps, "error" | "ref" | "classes"> {
	background?: "primary" | "contrast";
	label?: string;
	inputLabel?: string;
	error?: string;
	mandatory?: boolean;
	postLabel?: React.ReactNode;
	update?: (newVal: string) => void;
	classes?: TextFieldProps["classes"] & {
		formControl?: FormControlProps["classes"] & {
			container?: string;
		};
	};
}

const StyledTextField = withStyles({
	root: {
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "#CACACA",
			},
			"&:hover fieldset": {
				border: `1px solid ${brandingColor}`,
			},
			"&.Mui-focused fieldset": {
				border: `2px solid ${brandingColor}`,
			},
		},
	},
})(TextField);

const useStyles = makeStyles((theme) => ({
	label: {
		color: theme.palette.text.primary,
		marginBottom: 4,
		display: "flex",
		flexFlow: "row nowrap",
		alignItems: "center",
		justifyContent: "flex-start",
		...theme.typography.inputLabel,
		fontSize: 14,
	},
	postLabel: {
		marginLeft: 5,
		display: "flex",
		flexFlow: "row nowrap",
		alignItems: "flex-end",
		justifyContent: "flex-start",
		height: "1em",
	},
	contrast: {
		backgroundColor: theme.palette.background.default,
	},
	mandatoryField: {
		color: "#E63946",
		marginLeft: 8,
	},
}));

export const Input = forwardRef<HTMLInputElement, IInputProps>(function Input(
	{
		label,
		inputLabel,
		background = "primary",
		classes = {},
		error,
		id,
		mandatory = false,
		update = () => null,
		fullWidth = true,
		postLabel,
		...props
	},
	ref,
) {
	const styles = useStyles();

	const { formControl = {}, ...innerClasses } = classes;

	const { container, ...formControlClasses } = formControl;

	return (
		<FormControl
			fullWidth={fullWidth}
			className={container}
			classes={formControlClasses}
		>
			{label && (
				<label htmlFor={id} className={styles.label}>
					{label}
					{mandatory && (
						<span className={styles.mandatoryField}>*</span>
					)}
					{postLabel && (
						<div className={styles.postLabel}>{postLabel}</div>
					)}
				</label>
			)}
			<StyledTextField
				id={id}
				label={inputLabel}
				error={!!error}
				onChange={(e) => update(e.currentTarget.value)}
				variant="outlined"
				inputRef={ref}
				{...props}
				classes={{
					...innerClasses,
					root: clsx(
						{
							[styles.contrast]: background === "contrast",
						},
						innerClasses.root,
					),
				}}
			/>
			{!!error && <Typography color="error">{error}</Typography>}
		</FormControl>
	);
});
